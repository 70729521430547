// eslint-disable-next-line max-classes-per-file
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { createColumnHelper, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import firebase from './firebase';
import ShopsContext from './ShopsContext';
import dayjs from './dayjs';

interface Shop {
  id: string;
  short_name: string;
  remark?: string;
  remark_en?: string;
  flash_message?: string;
  flash_message_en?: string;
  flash_message_until?: string;
  opening_days?: string;
  opening_hours?: string;
  opening_days_en?: string;
  opening_hours_en?: string;
  initial_order_sequence?: number;
  max_order_sequence?: number;
  cooking_crew_level?: number;
  team_bonus_goal?: number;
  team_bonus_coupon_discount_percentage?: number;
  team_bonus_coupon_discount_amount?: number;
  holiday_start_date?: string;
  holiday_end_date?: string;
  friends_invitation_discount_percentage?: number;
}

class StringUpdateHandler {
  shopId: string;

  column: string;

  constructor(shopId: string, column: string) {
    this.shopId = shopId;
    this.column = column;
  }

  update(value: string, batch: firebase.firestore.WriteBatch) {
    const ref = firebase.firestore().collection('shops').doc(this.shopId);

    const updateHash = {};
    updateHash[this.column] = value;
    batch.update(ref, updateHash);

    return true;
  }
}

class NumberUpdateHandler {
  shopId: string;

  column: string;

  constructor(shopId: string, column: string) {
    this.shopId = shopId;
    this.column = column;
  }

  update(value: string, batch: firebase.firestore.WriteBatch) {
    const ref = firebase.firestore().collection('shops').doc(this.shopId);

    const updateHash = {};

    const intValue = Number.parseInt(value, 10);

    if (Number.isNaN(intValue)) {
      updateHash[this.column] = null;
      batch.update(ref, updateHash);
    } else {
      updateHash[this.column] = intValue;
      batch.update(ref, updateHash);
    }
    return true;
  }
}

class TimestampUpdateHandler {
  shopId: string;

  column: string;

  constructor(shopId: string, column: string) {
    this.shopId = shopId;
    this.column = column;
  }

  update(value: string, batch: firebase.firestore.WriteBatch) {
    const ref = firebase.firestore().collection('shops').doc(this.shopId);

    const updateHash = {};

    const time = dayjs.tz(value, 'Asia/Tokyo');
    if (!time.isValid()) {
      return false;
    }

    updateHash[this.column] = time.toDate();
    batch.update(ref, updateHash);

    return true;
  }
}

class DateUpdateHandler {
  shopId: string;

  column: string;

  constructor(shopId: string, column: string) {
    this.shopId = shopId;
    this.column = column;
  }

  update(value: string, batch: firebase.firestore.WriteBatch) {
    const ref = firebase.firestore().collection('shops').doc(this.shopId);
    const updateHash = {};

    if (!value) {
      updateHash[this.column] = null;
      batch.update(ref, updateHash);
      return true;
    }

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(value)) {
      return false;
    }

    updateHash[this.column] = value;
    batch.update(ref, updateHash);

    return true;
  }
}

const EditableCell = React.memo(
  ({ info, handler, setUpdatingCell }: { info: any; handler: any; setUpdatingCell: (updating: boolean) => void }) => {
    const [value, setValue] = useState(info.getValue() ?? '');
    const [isChanged, setIsChanged] = useState(false);

    const handleUpdate = (currentValue: string) => {
      if (!isChanged) return;
      setUpdatingCell(true);
      const batch = firebase.firestore().batch();
      const success = handler.update(currentValue, batch);
      if (success) {
        batch
          .commit()
          .then(() => {
            setUpdatingCell(false);
            setIsChanged(false);
          })
          .catch(() => {
            setValue(info.getValue() ?? '');
            setUpdatingCell(false);
            setIsChanged(false);
          });
      } else {
        setValue(info.getValue() ?? '');
        setUpdatingCell(false);
        setIsChanged(false);
      }
    };

    return (
      <input
        value={value}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          padding: '2px',
          backgroundColor: 'inherit',
          boxSizing: 'border-box',
        }}
        onChange={(e) => {
          setValue(e.target.value);
          setIsChanged(true);
        }}
        onFocus={(e) => e.target.select()}
        onBlur={(e) => handleUpdate(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            (e.currentTarget as HTMLInputElement).blur();
          }
        }}
      />
    );
  },
);

function ShopInfoBatchUpdate() {
  const [data, setData] = useState<Shop[]>([]);
  const [parentShopId, setParentShopId] = useState<string>();
  const [updatingCell, setUpdatingCell] = useState<boolean>(false);

  const { shops } = useContext(ShopsContext);

  const columnHelper = createColumnHelper<Shop>();

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (updatingCell) {
        e.preventDefault();
        e.returnValue = '更新中です。このページを離れてもよろしいですか？';
        return e.returnValue;
      }
      return undefined;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [updatingCell]);

  useEffect(() => {
    const unregisterShops = firebase
      .firestore()
      .collection('shops')
      .where('status', '==', 'active')
      .orderBy('order')
      .onSnapshot((snapshot) => {
        const shopsData = snapshot.docs
          .filter((doc) => {
            if (!parentShopId) return true;
            return doc.id === parentShopId || doc.data().kitchen_shop_id === parentShopId;
          })
          .map((doc) => ({
            id: doc.id,
            short_name: doc.data().short_name,
            remark: doc.data().remark || '',
            remark_en: doc.data().remark_en || '',
            flash_message: doc.data().flash_message || '',
            flash_message_en: doc.data().flash_message_en || '',
            flash_message_until: doc.data().flash_message_until
              ? dayjs(doc.data().flash_message_until.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
              : '',
            opening_days: doc.data().opening_days || '',
            opening_hours: doc.data().opening_hours || '',
            opening_days_en: doc.data().opening_days_en || '',
            opening_hours_en: doc.data().opening_hours_en || '',
            initial_order_sequence: doc.data().initial_order_sequence,
            max_order_sequence: doc.data().max_order_sequence,
            cooking_crew_level: doc.data().cooking_crew_level,
            team_bonus_goal: doc.data().team_bonus_goal,
            team_bonus_coupon_discount_percentage: doc.data().team_bonus_coupon_discount_percentage,
            team_bonus_coupon_discount_amount: doc.data().team_bonus_coupon_discount_amount,
            holiday_start_date: doc.data().holiday_start_date || '',
            holiday_end_date: doc.data().holiday_end_date || '',
            friends_invitation_discount_percentage: doc.data().friends_invitation_discount_percentage,
          }));

        setData(shopsData);
      });

    return () => {
      unregisterShops();
    };
  }, [parentShopId]);

  const change = (e) => {
    setParentShopId(e.target.value as string);
  };

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('short_name', {
        header: '',
        cell: (info) => info.getValue(),
        size: 300,
      }),
      columnHelper.accessor('remark', {
        header: '注意事項（日本語）',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new StringUpdateHandler(info.row.original.id, 'remark')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 250,
      }),
      columnHelper.accessor('remark_en', {
        header: '注意事項（英語）',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new StringUpdateHandler(info.row.original.id, 'remark_en')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 250,
      }),
      columnHelper.accessor('flash_message', {
        header: 'お知らせ（日本語）',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new StringUpdateHandler(info.row.original.id, 'flash_message')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 250,
      }),
      columnHelper.accessor('flash_message_en', {
        header: 'お知らせ（英語）',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new StringUpdateHandler(info.row.original.id, 'flash_message_en')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 250,
      }),
      columnHelper.accessor('flash_message_until', {
        header: 'お知らせ表示期限 (書式 2022-04-30 23:59)',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new TimestampUpdateHandler(info.row.original.id, 'flash_message_until')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 250,
      }),
      columnHelper.accessor('opening_days', {
        header: '営業日（日本語）',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new StringUpdateHandler(info.row.original.id, 'opening_days')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 120,
      }),
      columnHelper.accessor('opening_hours', {
        header: '営業時間（日本語）',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new StringUpdateHandler(info.row.original.id, 'opening_hours')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 120,
      }),
      columnHelper.accessor('opening_days_en', {
        header: '営業日（英語）',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new StringUpdateHandler(info.row.original.id, 'opening_days_en')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 120,
      }),
      columnHelper.accessor('opening_hours_en', {
        header: '営業時間（英語）',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new StringUpdateHandler(info.row.original.id, 'opening_hours_en')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 120,
      }),
      columnHelper.accessor('initial_order_sequence', {
        header: '注文番号初期値',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new NumberUpdateHandler(info.row.original.id, 'initial_order_sequence')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 50,
      }),
      columnHelper.accessor('max_order_sequence', {
        header: '注文番号最大値',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new NumberUpdateHandler(info.row.original.id, 'max_order_sequence')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 50,
      }),
      columnHelper.accessor('cooking_crew_level', {
        header: '盛り付けクルーレベル',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new NumberUpdateHandler(info.row.original.id, 'cooking_crew_level')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 50,
      }),
      columnHelper.accessor('team_bonus_goal', {
        header: 'チームボーナスゴール食数',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new NumberUpdateHandler(info.row.original.id, 'team_bonus_goal')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 50,
      }),
      columnHelper.accessor('team_bonus_coupon_discount_percentage', {
        header: 'チームボーナスクーポン割引率',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new NumberUpdateHandler(info.row.original.id, 'team_bonus_coupon_discount_percentage')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 50,
      }),
      columnHelper.accessor('team_bonus_coupon_discount_amount', {
        header: 'チームボーナスクーポン割引額',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new NumberUpdateHandler(info.row.original.id, 'team_bonus_coupon_discount_amount')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 50,
      }),
      columnHelper.accessor('holiday_start_date', {
        header: '長期休暇開始日',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new DateUpdateHandler(info.row.original.id, 'holiday_start_date')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 100,
      }),
      columnHelper.accessor('holiday_end_date', {
        header: '長期休暇終了日',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new DateUpdateHandler(info.row.original.id, 'holiday_end_date')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 100,
      }),
      columnHelper.accessor('friends_invitation_discount_percentage', {
        header: '友達紹介割引率',
        cell: (info) => (
          <EditableCell
            info={info}
            handler={new NumberUpdateHandler(info.row.original.id, 'friends_invitation_discount_percentage')}
            setUpdatingCell={setUpdatingCell}
          />
        ),
        size: 50,
      }),
    ];
  }, [columnHelper]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <select id="shops" className="form-control form-control-sm" onChange={change} style={{ width: '400px' }}>
        <option value="">親店舗選択</option>
        {shops === undefined
          ? ''
          : Object.keys(shops)
              .filter((shopId: string) => !shops[shopId].data()!.kitchen_shop_id && shops[shopId].data()!.enabled)
              .map((shopId: string) => (
                <option key={shopId} value={shopId}>
                  {shops[shopId].data()!.short_name}
                </option>
              ))}
      </select>

      {data && (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 100px)',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              overflowX: 'auto',
              overflowY: 'auto',
            }}
          >
            <table
              style={{
                borderCollapse: 'collapse',
                borderSpacing: 0,
                width: '100%',
                fontSize: '12px',
              }}
            >
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 3,
                }}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        style={{
                          ...(header.column.id === 'short_name'
                            ? {
                                position: 'sticky',
                                left: 0,
                                backgroundColor: '#f5f5f5',
                                zIndex: 4,
                                boxShadow: '2px 0 5px -2px #888',
                                minWidth: '300px',
                                width: '300px',
                              }
                            : {
                                backgroundColor: '#f5f5f5',
                              }),
                          padding: '4px',
                          border: '1px solid #ddd',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <tr
                    key={row.id}
                    style={{
                      backgroundColor: index % 2 === 1 ? '#f8f9fa' : 'white',
                    }}
                  >
                    {row.getVisibleCells().map((cell) =>
                      cell.column.id === 'short_name' ? (
                        <th
                          key={cell.id}
                          style={{
                            position: 'sticky',
                            left: 0,
                            backgroundColor: index % 2 === 1 ? '#f8f9fa' : 'white',
                            zIndex: 1,
                            boxShadow: '2px 0 5px -2px #888',
                            minWidth: '300px',
                            width: '300px',
                            padding: '2px',
                            border: '1px solid #ddd',
                            textAlign: 'left',
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </th>
                      ) : (
                        <td
                          key={cell.id}
                          style={{
                            backgroundColor: index % 2 === 1 ? '#f8f9fa' : 'white',
                            padding: '2px',
                            border: '1px solid #ddd',
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ),
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default ShopInfoBatchUpdate;
