import React, { useContext, useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import dayjs from './dayjs';
import firebase from './firebase';
import ShopsContext from './ShopsContext';
import salesChannels from './SalesChannels';

function AllSlots() {
  const [date, setDate] = useQueryParam('date', StringParam);
  const [salesChannel, setSalesChannel] = useQueryParam('sales_channel', StringParam);
  const [slots, setSlots] = useState<Array<any>>();
  const { shops } = useContext(ShopsContext);

  useEffect(() => {
    const dayjsDate = date ? dayjs.tz(date, 'Asia/Tokyo') : dayjs().tz('Asia/Tokyo');
    const startOfDay = dayjsDate.startOf('day').toDate();
    const endOfDay = dayjsDate.endOf('day').toDate();

    const unregisterShopsObserver = firebase
      .firestore()
      .collectionGroup('slots')
      .where('open', 'in', [true, false]) // indexを使うため
      .where('time', '>=', startOfDay)
      .where('time', '<', endOfDay)
      .orderBy('time')
      .onSnapshot((snap) => {
        console.log('onSnapshot AllSlots');
        const records: Array<any> = [];

        snap.forEach((docSnapshot) => {
          if (!salesChannel || shops[docSnapshot.data().shop_id].data()!.sales_channels?.includes(salesChannel)) {
            records.push(docSnapshot);
          }
        });

        setSlots(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, [date, shops, salesChannel]);

  const dateChanged = (e) => {
    setDate(e.target.value);
  };

  const salesChannelChanged = (e) => {
    setSalesChannel(e.target.value);
  };

  const renderSlot = (slot: firebase.firestore.DocumentSnapshot) => {
    const data = slot.data()!;

    const kitchenShop = shops[data.kitchen_shop_id];
    const shop = shops[data.shop_id];
    const cols: Array<JSX.Element> = [];

    if (kitchenShop.id === shop.id) {
      cols.push(<td>{kitchenShop.data()!.short_name}</td>);
    } else {
      cols.push(
        <td>
          {kitchenShop.data()!.short_name} {shop.data()!.short_name}
        </td>,
      );
    }

    cols.push(
      <td>
        {dayjs(data.time.toDate()).tz('Asia/Tokyo').format('HH:mm')}-
        {dayjs(data.time_until.toDate()).tz('Asia/Tokyo').format('HH:mm')}
        {data.batch_delivery_close_at
          ? ` (〆${dayjs(data.batch_delivery_close_at.toDate()).tz('Asia/Tokyo').format('HH:mm')})`
          : ''}
      </td>,
    );

    // takeoutのときはtimeoutがセットされないため時間で判断(直したい)
    const status =
      data.timeout || new Date(+data.time_until.toDate() - 5 * 60 * 1000) < new Date()
        ? 'timeout'
        : !data.open
        ? 'soldout'
        : data.capacity - (data.ordered ?? 0) < 3
        ? 'soon'
        : null;

    let statusNode: JSX.Element;
    switch (status) {
      case 'timeout':
        statusNode = (
          <span className="badge badge-dark" role="alert">
            終了
          </span>
        );
        break;
      case 'soldout':
        statusNode = (
          <span className="badge badge-danger" role="alert">
            売り切れ
          </span>
        );
        break;
      case 'soon':
        statusNode = (
          <span className="badge badge-warning" role="alert">
            売り切れ間近
          </span>
        );
        break;
      default:
        statusNode = (
          <span className="badge badge-primary" role="alert">
            受付中
          </span>
        );
    }

    cols.push(<td>{statusNode}</td>);

    // ?? 0はすべてのデフォル値がセットされたら後で外す(2022/11中旬以降ok)
    if (status === 'soldout' || status === 'timeout') {
      cols.push(<td>{data.ordered ?? 0}</td>);
    } else {
      cols.push(
        <td>
          {data.ordered ?? 0} / {data.available + (data.ordered ?? 0)}
        </td>,
      );
    }

    return cols;
  };

  return (
    <div className="container">
      <input type="date" onChange={dateChanged} defaultValue={date ?? ''} />

      <select className="form-control-sm" defaultValue={salesChannel || ''} onChange={salesChannelChanged}>
        <option key="all" value="">
          すべて
        </option>
        {salesChannels?.map((channel) => (
          <option key={channel.value} value={channel.value}>
            {channel.name}
          </option>
        ))}
      </select>

      {slots === undefined ? (
        <>loading</>
      ) : (
        <>
          <div className="container-fluid h-100">
            <table className="table table-sm">
              <tbody>
                {slots.map((slot) => (
                  <tr key={`${slot.data().shop_id}-${slot.id}`}>{renderSlot(slot)}</tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default AllSlots;
