/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NavLink } from 'react-router-dom';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import dayjs from './dayjs';
import salesChannels from './SalesChannels';
import useSalesPresets from './hooks/useSalesPresets';
import usePasses from './hooks/usePasses';
import useTags from './hooks/useTags';

function Messages(props: { location }) {
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState<any>();

  const [waitingMessage, setWaitingMessage] = useState<string>();

  const [showParentShopsSelection, setShowParentShopsSelection] = useState(false);

  const [includeClosedShop, setIncludeClosedShop] = useState(false);

  const [sendOption, setSendOption] = useState<'timeSpecified' | 'batchDelivery' | 'hakase'>('timeSpecified');

  const defaultImageUrl = 'https://tokyomixcurry.com/mobile/images/app/notifications/default.jpg';
  const defaultCoverImageUrl = 'https://tokyomixcurry.com/mobile/images/app/messages/default.jpg';
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');

  const watchImageUrl = watch('image_url', defaultImageUrl);
  const watchCoverImageUrl = watch('cover_image_url', defaultCoverImageUrl);

  const [shops, setShops] = useState<any>();
  const [parentShops, setParentShops] = useState<any>();

  const salesPresets = useSalesPresets();
  const tags = useTags();
  const passes = usePasses();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    let query: firebase.firestore.Query = firebase.firestore().collection('shops');

    if (!includeClosedShop) {
      query = query.where('status', '==', 'active');
    }

    const unregisterShopsObserver = query.orderBy('order').onSnapshot((snap) => {
      console.log('shops updated');
      const records = {};
      const parentRecords = {};
      snap.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        records[docSnapshot.id] = data;

        if (!data.kitchen_shop_id) {
          parentRecords[docSnapshot.id] = data;
        }
      });

      setShops(records);
      setParentShops(parentRecords);
    });

    return () => {
      unregisterShopsObserver();
    };
  }, [includeClosedShop]);

  const emptyDiv = () => <div />;

  const handleUpload = (e, fieldName) => {
    e.preventDefault();

    const file = e.target.files[0];

    let fileName;
    if (file.type === 'image/png') {
      fileName = `${new Date().getTime()}.png`;
    } else if (file.type === 'image/jpeg') {
      fileName = `${new Date().getTime()}.jpg`;
    } else {
      throw new Error("shouldn't come here");
    }

    const path = '/messages';
    const fullPath = `${path}/${fileName}`;
    const uploadTask = firebase.storage().ref(fullPath).put(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress function ...
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        progressMessage(progress);
      },
      (error) => {
        console.log(error);
      },
      async () => {
        if (uploadTask.snapshot.totalBytes > 500000) {
          window.alert('画像サイズは500キロバイト以内にしてください');
        } else {
          const fileUrl = await uploadTask.snapshot.ref.getDownloadURL();
          setValue(fieldName, fileUrl);
        }
      },
    );
  };

  const progressMessage = (progress: number) => {
    if (progress === 100) {
      setLoading(false);
    } else if (progress > 0) {
      setLoading(true);
      setWaitingMessage(`${progress}%`);
    } else {
      setLoading(false);
    }
  };

  const showNormalConfirm = () => {
    return new Promise<boolean>((resolve) => {
      confirmAlert({
        title: 'メッセージの送信予約を行います。',
        message: '',
        buttons: [
          {
            label: '送信する',
            onClick: () => resolve(true),
          },
          {
            label: 'キャンセルする',
            onClick: () => resolve(false),
          },
        ],
        childrenElement: () => emptyDiv(),
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        onClickOutside: () => resolve(false),
        onKeypressEscape: () => resolve(false),
      });
    });
  };

  const showCouponWarning = () => {
    return new Promise<boolean>((resolve) => {
      confirmAlert({
        title: '⚠️ 警告 ⚠️',
        message:
          'メッセージ内にクーポンの記載がありますが、「送信するクーポンコード」が未入力です。一部機能が正常に動作しない可能性があります。本当に送信しますか？',
        buttons: [
          {
            label: '送信する',
            onClick: () => resolve(true),
          },
          {
            label: 'キャンセル',
            onClick: () => resolve(false),
          },
        ],
        childrenElement: () => emptyDiv(),
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        onClickOutside: () => resolve(false),
        onKeypressEscape: () => resolve(false),
      });
    });
  };

  const onSubmit = async (argData) => {
    const data = { ...argData };

    if (data.preview) {
      submit(data);
      return;
    }

    // クーポンに関するバリデーション
    const hasCouponKeyword =
      data.subject?.toLowerCase().includes('クーポン') ||
      data.subject?.toLowerCase().includes('coupon') ||
      data.body?.toLowerCase().includes('クーポン') ||
      data.body?.toLowerCase().includes('coupon');

    if (hasCouponKeyword && !data.coupon_code) {
      const shouldContinue = await showCouponWarning();
      if (!shouldContinue) return;
    }

    const confirmed = await showNormalConfirm();
    if (confirmed) {
      submit(data);
    }
  };

  const sendMessage = () => {
    setValue('preview', false);

    formRef.current!.dispatchEvent(new Event('submit', { bubbles: true }));
  };

  const updatePreview = () => {
    unregister('subject');
    unregister('body');
    unregister('send_at');

    setValue('preview', true);

    formRef.current!.dispatchEvent(new Event('submit', { bubbles: true }));
  };

  const eraseWhenEmpty = (event) => {
    // when incompleate date/time, erase it
    // e.g. when a user input just `2019-02-01` and no time, looks like inputted but it's actually empty and it's misunderstading. so erase all.
    if (!event.target.value) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const submit = (argData) => {
    const data = { ...argData };
    const apiEndPoint = `${process.env.REACT_APP_api_server}/messages/`;
    const auth = firebase.auth();
    if (data.preview) {
      setWaitingMessage('配信者数を計算中');
      setLoading(true);
    } else {
      setWaitingMessage('メッセージ配信登録中');
      setLoading(true);
    }

    data.confirmed = true;

    if (data.expires_at) {
      data.expires_at = dayjs.tz(data.expires_at, 'Asia/Tokyo').unix();
    } else {
      data.expires_at = null;
    }

    if (data.send_at) {
      data.send_at = dayjs.tz(data.send_at, 'Asia/Tokyo').unix();
    } else {
      data.send_at = null;
    }

    if (data.send2_at) {
      data.send2_at = dayjs.tz(data.send2_at, 'Asia/Tokyo').unix();
    } else {
      data.send2_at = null;
    }

    if (data.send3_at) {
      data.send3_at = dayjs.tz(data.send3_at, 'Asia/Tokyo').unix();
    } else {
      data.send3_at = null;
    }

    if (data.segment.joined_before) {
      data.segment.joined_before = dayjs.tz(data.segment.joined_before, 'Asia/Tokyo').unix();
    } else {
      data.segment.joined_before = null;
    }

    if (!data.segment.sales_preset_ids || data.segment.sales_preset_ids === 0) {
      data.segment.sales_preset_ids = null;
    }

    if (!data.segment.tag_ids || data.segment.tag_ids === 0) {
      data.segment.tag_ids = null;
    }

    if (data.segment.joined_after) {
      data.segment.joined_after = dayjs.tz(data.segment.joined_after, 'Asia/Tokyo').unix();
    } else {
      data.segment.joined_after = null;
    }

    data.segment.exclude_ordering = !!data.segment.exclude_ordering;

    if (!data.segment.active_passes || data.segment.active_passes === 0) {
      data.segment.active_passes = null;
    }

    data.segment.exclude_pass_customers = !!data.segment.exclude_pass_customers;
    data.segment.exclude_no_slots = !!data.segment.exclude_no_slots;

    data.team_bonus = !!data.team_bonus;

    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 200 || response.status === 201) {
            if (data.preview) {
              register('subject');
              register('body');
              setPreview(responseJson.preview);
            } else {
              setMessageSuccess('配信予約完了しました。');
              setSendOption('timeSpecified');
              reset();
            }
          } else {
            setMessageError(responseJson.error.message);
          }
          setLoading(false);
        })
        .catch((error) => {
          setMessageError('配信予約に失敗しました');
          setLoading(false);
        });
    });
  };

  const [defaultCustomerIds] = useQueryParam('customer_ids', withDefault(StringParam, ''));

  const parentShopsRef = React.createRef<HTMLSelectElement>();
  const shopSelectRef: { [shopId: string]: HTMLOptionElement | null } = {};

  const addShops = (salesChannel: string) => {
    for (const parentShopSelected of Array.from(parentShopsRef.current!.selectedOptions)) {
      const parentShopId = parentShopSelected.value;
      for (const shopId of Object.keys(shops)) {
        const shopData = shops[shopId];

        if (shopData.kitchen_shop_id === parentShopId && shopData.sales_channels?.includes(salesChannel)) {
          const e = shopSelectRef[shopId];
          if (e) {
            e.selected = true;
          }
        }
      }
    }
  };

  const includeClosedShopRef = React.createRef<HTMLInputElement>();

  const clickedIncludeClosedShop = () => {
    setIncludeClosedShop(includeClosedShopRef.current?.checked || false);
  };

  return (
    <div className="container-fluid h-100">
      <NavLink to="/admin/scheduled_messages/" className="nav-link" activeClassName="active">
        送信予約メッセージ
      </NavLink>

      {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

      {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

      <form id="message-form" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <h3>送信セグメント</h3>

        <div className="form-group row">
          <div className="col-sm-4">
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => {
                updatePreview();
              }}
            >
              送信予定者数を表示
            </button>
            {preview && (
              <div>
                <div>
                  送信予定数:
                  {preview.target_customers}
                </div>
                送信予定者(サンプル)
                <ul>
                  {preview.sample_customers.map((customer, i) => (
                    <li key={customer.customer_id}>
                      <NavLink
                        key={customer.customer_id}
                        to={`/customers/${customer.customer_id}`}
                        target="_blank"
                        className="nav-link"
                        activeClassName="active"
                      >
                        {`ユーザー ${i + 1}`}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="selectShop" className="col-sm-2 col-form-label">
            店舗
          </label>
          <div className="col-sm-4">
            <select size={10} multiple {...register('segment.shop_ids', {})}>
              {shops &&
                Object.keys(shops).map((shopId) => (
                  <option
                    key={shopId}
                    value={shopId}
                    ref={(input) => {
                      shopSelectRef[shopId] = input;
                    }}
                  >
                    {shops[shopId].short_name}
                  </option>
                ))}
              <option value="OTHERS">どの店舗にも属していない人</option>
            </select>
            <br />
            <small className="form-text text-muted">
              <input
                id="checkboxIncludeClosedShop"
                type="checkbox"
                ref={includeClosedShopRef}
                onClick={clickedIncludeClosedShop}
              />
              &nbsp;
              <label htmlFor="checkboxIncludeClosedShop">閉店済店舗含む</label>
              <br />
              最後の注文がこの店舗。TMCで一度も注文したことない人は、最後にこの店舗を選択したユーザー
            </small>
          </div>
          {showParentShopsSelection ? (
            <div className="col-sm-5">
              <div className="row">
                <div className="col">
                  {salesChannels.map((channel) => (
                    <div className="row">
                      <div className="col">
                        <button type="button" className="btn btn-sm btn-dark" onClick={() => addShops(channel.value)}>
                          {channel.name}追加
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  <select size={10} multiple ref={parentShopsRef}>
                    {parentShops
                      ? Object.keys(parentShops).map((shopId) => (
                          <option key={shopId} value={shopId}>
                            {parentShops[shopId].short_name}
                          </option>
                        ))
                      : ''}
                  </select>
                </div>
              </div>
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-light"
              onClick={() => {
                setShowParentShopsSelection(true);
              }}
            >
              親店舗の選択
            </button>
          )}
        </div>

        <div className="form-group row">
          <label htmlFor="inputDistance" className="col-sm-2 col-form-label">
            購入地点の店舗からの距離(メートル) 内側
          </label>
          <div className="col-sm-6">
            <input type="number" id="inputDistance" {...register('segment.distance', {})} />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputDistanceOutside" className="col-sm-2 col-form-label">
            購入地点の店舗からの距離(メートル) 外側
          </label>
          <div className="col-sm-6">
            <input type="number" id="inputDistanceOutside" {...register('segment.distance_outside', {})} />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputCouponAdded" className="col-sm-2 col-form-label">
            このクーポンがクーポンボックスにある人(*でワイルドカード指定可)
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              id="inputCouponAdded"
              size={60}
              {...register('segment.coupon_added', {})}
              placeholder="クーポンコードをカンマ区切り"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputCouponNotAdded" className="col-sm-2 col-form-label">
            このクーポンがクーポンボックスにない人(*でワイルドカード指定可)
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              id="inputCouponNotAdded"
              size={60}
              {...register('segment.coupon_not_added', {})}
              placeholder="クーポンコードをカンマ区切り"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputCouponUsed" className="col-sm-2 col-form-label">
            このクーポンを使ったことがある人(*でワイルドカード指定可)
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              id="inputCouponUsed"
              size={60}
              {...register('segment.coupon_used', {})}
              placeholder="クーポンコードをカンマ区切り"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputCouponNotUsed" className="col-sm-2 col-form-label">
            このクーポンを使ったことがない人(*でワイルドカード指定可)
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              id="inputCouponNotUsed"
              size={60}
              {...register('segment.coupon_not_used', {})}
              placeholder="クーポンコードをカンマ区切り"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputCurriesDone" className="col-sm-2 col-form-label">
            N回食べたことがある人。
            <br />
            例: 1 (1回) / &gt;=2 (2回以上) / &lt;3 (3回未満) / &gt;=2, &lt;5 (2回以上5回未満)
          </label>
          <div className="col-sm-6">
            <input type="text" id="inputCurriesDone" {...register('segment.number_of_curries_done', {})} />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputDaysAfterLastVisit" className="col-sm-2 col-form-label">
            N日間以上食べていない人。(昨日食べた人が1)
          </label>
          <div className="col-sm-2">
            <input type="text" id="inputDaysAfterLastVisit" {...register('segment.days_after_last_visit', {})} />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputDaysRecentVisit" className="col-sm-2 col-form-label">
            N日間以内に食べた人 (昨日食べた人が1)
          </label>
          <div className="col-sm-2">
            <input type="text" id="inputDaysRecentVisit" {...register('segment.days_recent_visit', {})} />
          </div>
          &nbsp;
          <div className="col-sm-2">
            <input
              type="text"
              id="inputDaysRecentVisitMinimumCurry"
              placeholder="X回以上注文(省略時は1)"
              {...register('segment.days_recent_visit_minimum_orders', {})}
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputLastOrderHHMMFrom" className="col-sm-2 col-form-label">
            最後に注文した時間
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              id="inputLastOrderHHMMFrom"
              {...register('segment.last_order_hhmm_from', {
                pattern: /[0-9][0-9]:[0-9][0-9]/,
              })}
              placeholder="hh:mm"
              size={5}
            />
            {' ～ '}
            <input
              type="text"
              id="inputLastOrderHHMMTo"
              {...register('segment.last_order_hhmm_to', {
                pattern: /[0-9][0-9]:[0-9][0-9]/,
              })}
              placeholder="hh:mm"
              size={5}
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputLastOrderPrice" className="col-sm-2 col-form-label">
            前回注文金額(割引後、税込み)
            <br />
            例: 700 (700円)
            <br /> &gt;=700 (700円以上)
            <br /> &lt;1000 (1000円未満)
            <br />
            &gt;=500, &lt;1500 (500円以上1500円未満)
          </label>
          <div className="col-sm-6">
            <input type="text" id="inputLastOrderPrice" {...register('segment.last_order_price', {})} />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputLastPickupHHMMFrom" className="col-sm-2 col-form-label">
            最後に注文したスロットの時間
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              id="inputLastPickupHHMMFrom"
              {...register('segment.last_pickup_hhmm_from', {
                pattern: /[0-9][0-9]:[0-9][0-9]/,
              })}
              placeholder="hh:mm"
              size={5}
            />
            {' ～ '}
            <input
              type="text"
              id="inputLastPickupHHMMTo"
              {...register('segment.last_pickup_hhmm_to', {
                pattern: /[0-9][0-9]:[0-9][0-9]/,
              })}
              placeholder="hh:mm"
              size={5}
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputJoinedDatetimeBefore" className="col-sm-2 col-form-label">
            この日時以前にアプリダウンロードした人
          </label>
          <div className="col-sm-6">
            <input type="datetime-local" {...register('segment.joined_before', {})} />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputJoinedDatetimeAfter" className="col-sm-2 col-form-label">
            この日時以降にアプリダウンロードした人
          </label>
          <div className="col-sm-6">
            <input type="datetime-local" {...register('segment.joined_after', {})} />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputStampCounts" className="col-sm-2 col-form-label">
            現在アクティブなスタンプカードの利用回数
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              id="inputStampCounts"
              size={60}
              {...register('segment.stamp_counts', {})}
              placeholder="回数をカンマ区切り"
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label">前回注文のプリセット指定</label>
          <div className="col-sm-8">
            <select multiple size={5} {...register('segment.sales_preset_ids', {})}>
              {salesPresets &&
                salesPresets.map((salesPreset) => (
                  <option key={salesPreset.data().id} value={salesPreset.data().id}>
                    {salesPreset.data().name}
                  </option>
                ))}
            </select>
            <small className="form-text text-muted">複数選択時は前回注文で1つ以上が注文されていたら対象</small>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label">前回注文のタグ指定</label>
          <div className="col-sm-8">
            <select multiple size={5} {...register('segment.tag_ids', {})}>
              {tags &&
                tags.map((tag) => (
                  <option key={tag.data().id} value={tag.data().id}>
                    {tag.data().name}
                  </option>
                ))}
            </select>
            <small className="form-text text-muted">複数選択時は前回注文で1つ以上が注文されていたら対象</small>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputOnlyPassCustomers" className="col-sm-2 col-form-label">
            アクティブなパスを所持しているユーザーだけに送信
          </label>
          <div className="col-sm-6">
            <select size={5} multiple {...register('segment.active_passes', {})}>
              {passes &&
                passes.map((pass) => (
                  <option key={pass.id} value={pass.id}>
                    {pass.data()?.name}
                  </option>
                ))}
            </select>
            <br />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputExcludePassCustomers" className="col-sm-2 col-form-label">
            パスのユーザーを除外
          </label>
          <div className="col-sm-6">
            <input type="checkbox" defaultChecked {...register('segment.exclude_pass_customers', {})} value="1" />
            <br />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputExcludeOrderingCustomer" className="col-sm-2 col-form-label">
            注文中または調理完了後4時間以内のユーザーを除外
          </label>
          <div className="col-sm-6">
            <input type="checkbox" defaultChecked {...register('segment.exclude_ordering', {})} value="1" />
            <br />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputExcludeNoSlotsCustomers" className="col-sm-2 col-form-label">
            送信時点で注文可能なスロットが存在しない場合に除外
          </label>
          <div className="col-sm-6">
            <input type="checkbox" defaultChecked {...register('segment.exclude_no_slots', {})} value="1" />
            &nbsp; 対象日 &nbsp;
            <input type="date" {...register('segment.exclude_no_slots_date', {})} />
            <small className="form-text text-muted">日付無指定時は送信された日のスロットが対象</small>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputUpliftModeling" className="col-sm-2 col-form-label">
            アップリフトモデリング
          </label>
          <div className="col-sm-6">
            <select id="inputUpliftModeling" defaultValue="not_used" {...register('segment.uplift_modeling', {})}>
              <option value="not_used">使用しない</option>
              <option value="target_only">対象者のみ</option>
              <option value="non_target_only">非対象者のみ</option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputCouponUsed" className="col-sm-2 col-form-label">
            ユーザーID指定
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              defaultValue={defaultCustomerIds}
              id="inputCustomers"
              size={80}
              {...register('segment.customer_ids', {})}
              placeholder="ユーザーIDをカンマ区切り"
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputCouponUsed" className="col-sm-2 col-form-label">
            アプリバージョン指定
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              id="inputAppVersion"
              size={80}
              {...register('segment.app_version', {})}
              placeholder="指定されたバージョン以上のユーザーに送信"
            />
          </div>
        </div>

        <hr />

        <div className="form-group row">
          <label htmlFor="inputCouponUsed" className="col-sm-2 col-form-label">
            グループ指定 (A/Bテスト用){' '}
          </label>
          <div className="col-sm-6">
            Group 0{' '}
            <input type="checkbox" defaultChecked id="inputGroups" {...register('segment.groups[0]', {})} value="0" />
            <br />
            Group 1{' '}
            <input type="checkbox" defaultChecked id="inputGroups" {...register('segment.groups[1]', {})} value="1" />
            <br />
            Group 2{' '}
            <input type="checkbox" defaultChecked id="inputGroups" {...register('segment.groups[2]', {})} value="2" />
            <br />
            Group 3{' '}
            <input type="checkbox" defaultChecked id="inputGroups" {...register('segment.groups[3]', {})} value="3" />
            <br />
          </div>
        </div>
        <hr />
        <h3>送信内容</h3>
        <div className="form-group row">
          <label htmlFor="inputNotificationChannel" className="col-sm-2 col-form-label">
            通知チャンネル
          </label>
          <div className="col-sm-8">
            <select id="inputNotificationChannel" defaultValue="marketing" {...register('notification_channel', {})}>
              <option value="marketing">TOKYO MIX CURRYからのお知らせ</option>
              <option value="order">注文に関するお知らせ</option>
            </select>
            &nbsp;
            <br />
            <p className="text-danger">
              <i className="fa fa-exclamation-triangle" aria-hidden="true" />
              ユーザーの設定に関係なく通知する&nbsp;
              <input type="checkbox" {...register('force_notification', {})} />
            </p>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputSubject" className="col-sm-2 col-form-label">
            タイトル
          </label>
          <div className="col-sm-8">
            <input type="text" id="inputSubject" size={60} {...register('subject', { required: true })} />
          </div>
          <div className="col-sm-2">
            <small id="error_subject" className="text-danger">
              {errors.subject && 'タイトルは必須です'}
            </small>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputCouponCode" className="col-sm-2 col-form-label">
            送信するクーポンコード
          </label>
          <div className="col-sm-8">
            <input type="text" id="inputCouponCode" size={60} {...register('coupon_code', {})} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputShareable" className="col-sm-2 col-form-label">
            共有オプションを表示
          </label>
          <div className="col-sm-8">
            <input type="checkbox" defaultChecked id="inputShareable" {...register('shareable', {})} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputImageUrl" className="col-sm-2 col-form-label">
            通知画像のURL
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              id="inputImageUrl"
              size={60}
              {...register('image_url', {})}
              defaultValue={defaultImageUrl}
            />
            <br />
            <small className="form-text text-muted">アプリのpush通知で使用されます</small>
            <input
              type="file"
              name="file"
              className="file-select"
              accept="image/jpeg, image/png"
              onChange={(e) => handleUpload(e, 'image_url')}
            />
            <button
              type="button"
              className="btn btn-small btn-light"
              onClick={(e) => {
                e.preventDefault();
                setValue('image_url', defaultImageUrl);
              }}
            >
              デフォルト画像に戻す
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-small btn-light"
              onClick={(e) => {
                e.preventDefault();
                setValue('image_url', '');
              }}
            >
              画像を削除
            </button>
            {watchImageUrl ? <img alt={watchImageUrl} src={watchImageUrl} width="400px" /> : null}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputCoverImageUrl" className="col-sm-2 col-form-label">
            カバー画像のURL
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              id="inputCoverImageUrl"
              size={60}
              {...register('cover_image_url', {})}
              defaultValue={defaultCoverImageUrl}
            />
            <br />
            <small className="form-text text-muted">
              アプリ内メッセージの本文の上に表示されます。推奨アスペクト比 3:4(縦長)
            </small>
            <input
              type="file"
              name="file"
              className="file-select"
              accept="image/jpeg, image/png"
              onChange={(e) => handleUpload(e, 'cover_image_url')}
            />
            <button
              type="button"
              className="btn btn-small btn-light"
              onClick={(e) => {
                e.preventDefault();
                setValue('cover_image_url', defaultCoverImageUrl);
              }}
            >
              デフォルト画像に戻す
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-small btn-light"
              onClick={(e) => {
                e.preventDefault();
                setValue('cover_image_url', '');
              }}
            >
              画像を削除
            </button>
            {watchCoverImageUrl ? <img src={watchCoverImageUrl} alt={watchCoverImageUrl} width="400px" /> : null}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputBody" className="col-sm-2 col-form-label">
            チームボーナスメッセージを本文に挿入(該当時のみ)
          </label>
          <div className="col-sm-8">
            <input type="checkbox" {...register('team_bonus', {})} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputBody" className="col-sm-2 col-form-label">
            本文
          </label>
          <div className="col-sm-8">
            <textarea id="inputBody" rows={20} cols={80} {...register('body', { required: true })} />
          </div>
          <div className="col-sm-2">
            <small id="error_body" className="text-danger">
              {errors.body && '本文は必須です'}
            </small>
          </div>
        </div>

        <hr />
        <h3>送信タイミング</h3>
        <div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="time_specified"
              id="send_option1"
              defaultChecked={sendOption === 'timeSpecified'}
              {...register('send_option', {})}
              onChange={() => {
                setSendOption('timeSpecified');
              }}
            />
            <label className="form-check-label" htmlFor="send_option1">
              時間指定
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="batch_delivery_reminder"
              id="send_option2"
              defaultChecked={sendOption === 'batchDelivery'}
              {...register('send_option', {})}
              onChange={() => {
                unregister('send_at');
                unregister('send2_at');
                unregister('send3_at');
                unregister('expires_at');
                setSendOption('batchDelivery');
              }}
            />
            <label className="form-check-label" htmlFor="send_option2">
              オフィス配達の締め切り15分前(繰り返し配信)
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="hakase"
              id="send_option3"
              defaultChecked={sendOption === 'hakase'}
              {...register('send_option', {})}
              onChange={() => {
                unregister('send_at');
                unregister('send2_at');
                unregister('send3_at');
                unregister('expires_at');
                setSendOption('hakase');
              }}
            />
            <label className="form-check-label" htmlFor="send_option3">
              博士ロジック
            </label>
          </div>
        </div>
        <hr />
        {sendOption === 'timeSpecified' && (
          <>
            <div className="form-group row">
              <label htmlFor="inputScheduledTime" className="col-sm-3 col-form-label">
                メッセージ送信する時間
              </label>
              <div className="col-sm-2">
                <input type="datetime-local" {...register('send_at', { required: true, onBlur: eraseWhenEmpty })} />
                <small id="error_body" className="text-danger">
                  {errors.send_at && '必須です'}
                </small>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputScheduledTime" className="col-sm-3 col-form-label">
                メッセージ送信する時間(2回目)
                <br />
                (無指定の場合は送信されません)
              </label>
              <div className="col-sm-2">
                <input type="datetime-local" {...register('send2_at', { onBlur: eraseWhenEmpty })} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputScheduledTime" className="col-sm-3 col-form-label">
                メッセージ送信する時間(3回目)
                <br />
                (無指定の場合は送信されません)
              </label>
              <div className="col-sm-2">
                <input type="datetime-local" {...register('send3_at', { onBlur: eraseWhenEmpty })} />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputExpiresAt" className="col-sm-3 col-form-label">
                メッセージボックスから削除する日時
              </label>
              <div className="col-sm-2">
                <input
                  type="datetime-local"
                  defaultValue={dayjs().tz('Asia/Tokyo').add(7, 'days').endOf('day').format('YYYY-MM-DDTHH:mm')}
                  {...register('expires_at', { onBlur: eraseWhenEmpty })}
                />
                <small className="form-text text-muted" />
              </div>
            </div>
          </>
        )}

        {sendOption === 'batchDelivery' && (
          <>
            <div className="alert alert-warning" role="alert">
              注意:
            </div>
            <ul>
              <li>複数スロットある場合はそれぞれのスロットの15分前に送信されます</li>
              <li>スロットの残数が0の場合には送信されません</li>
              <li>法人デリバリー以外の店舗が選択されていても送信されません</li>
              <li>メッセージボックスからは当日の深夜削除されます</li>
            </ul>
          </>
        )}
        {sendOption === 'hakase' && (
          <>
            <div className="alert alert-warning" role="alert">
              注意:
            </div>
            <ul>
              <li>博士ロジックのタイミングでユーザーにあわせたタイミングで指定した日に複数回の通知が行われます</li>
            </ul>
            <div className="form-group row">
              <label htmlFor="inputScheduledTime" className="col-sm-3 col-form-label">
                メッセージ送信する日
              </label>
              <div className="col-sm-2">
                <input type="date" {...register('hakase_send_date', { required: true, onBlur: eraseWhenEmpty })} />
                <small id="error_body" className="text-danger">
                  {errors.hakase_send_date && '必須です'}
                </small>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputExpiresAt" className="col-sm-3 col-form-label">
                メッセージボックスから削除する日時
              </label>
              <div className="col-sm-2">
                <input
                  type="datetime-local"
                  defaultValue={dayjs().tz('Asia/Tokyo').add(7, 'days').endOf('day').format('YYYY-MM-DDTHH:mm')}
                  {...register('expires_at', { onBlur: eraseWhenEmpty })}
                />
                <small className="form-text text-muted" />
              </div>
            </div>
          </>
        )}
        <hr />
        <input type="button" className="btn btn-primary" value="送信" onClick={sendMessage} />
      </form>

      <WindmillSpinnerOverlay loading={loading} message={waitingMessage} />
    </div>
  );
}

export default Messages;
